import './home.3dtur.scss';

function Home3dTur() {
	return (
		<div className='home-3dtur'>
			<section className='section'>
				<div className='container'>
					<h2 className='subtitle'>3D Тур</h2>
					<iframe
						title='3dtur'
						src='https://my.matterhub.ru/8/GayBwuLXvXp/?m=GayBwuLXvXp'></iframe>
				</div>
			</section>
		</div>
	);
}

export default Home3dTur;
