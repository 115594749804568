import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
    const state = useLocation();
    const { pathname } = state;

    // scrool to top
    useEffect(() => {
        let timer1 = setTimeout(() => {
            if (pathname.includes("/catalog/")) {
                // localStorage.removeItem("scrollHeight");
            } else {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
                localStorage.removeItem("scrollHeight");
                localStorage.setItem("page", 1);
            }
        }, 50);

        return () => {
            clearTimeout(timer1);
        };
    }, [pathname]);

    return children || null;
};

export default ScrollToTop;